.template-select-label .spinner {
    display: inline;
    margin-left: 1em;
}

.template-select-label .spinner svg {
    vertical-align: middle;
}

.template-preview-container table{
    margin: 0px !important;
}

.documentSection {
    width: fit-content;
    margin: auto;
}

.documentSection .documentPreview {
    padding: 5px;
}

.documentCard {
    background-color: #697582 !important;
}
