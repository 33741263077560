.fcp-componentShell {
  background: white;
  padding: 20px 10px 20px 10px;
  min-height: 450px;
}

.fcp-componentShell__title {
  font-weight: 500;
  margin-bottom: 25px;
}

.fcp-componentShell__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fcp-componentShellBody__inner {
  width: 90%;
}

.fcp-fulfillmentConfigurationErrorPage__title {
  text-align: center;
}

.fcp-fulfillmentConfigurationErrorPage__appLinks {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

.fcp-fulfillmentConfigurationErrorPageAppLinks__link {
  margin-bottom: 10px;
}

.fcp-fulfillmentConfigurationErrorPageAppLinksLink__Icon {
  padding-right: 10px;
}

.fcp-fulfillmentConfigurationErrorPage__emailLink {
  text-align: center;
  margin-bottom: 40px;
}

.fcp-fulfillmentConfigurationErrorPage__tryAgainText {
  text-align: center;
  margin-bottom: 10px;
}

.fcp-fulfillmentConfigurationErrorPage__reloadButton {
  display: flex;
  justify-content: center;
}

.fcp-fulfillmentConfigurationErrorPage__alert {
  max-width: 700px;
}

.fcp-fulfillmentConfigurations__loadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.fcp-fulfillmentConfigurations__actionButtons {
  display: flex;
  justify-content: flex-end;
}

.fcp-fulfillmentConfigurationsActionButtons__primaryButton {
  margin-left: 30px;
}

.fcp-selectedConfigurationDisplay {
  display: flex;
  justify-content: space-between;
  padding: 0 37px;
  margin: 20px auto;
}

.fcp-selectedConfigurationDisplay__selectionPrompt {
  padding-top: 20px;
}

.fcp-selectedConfigurationDisplay__hasSelectedText {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 2px;
}

.fcp-versionsList__rowContents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fcp-versionsList__table.ReactTable>.rt-table .rt-tr>.rt-td:first-child {
  padding: 20px 20px 20px 30px !important;
}

.ReactTable.fcp-versionsList__table>.pagination-bottom>.rt-pagination {
  justify-content: space-between;
}

.ReactTable.fcp-fulfillmentConfigurationsList__table>.pagination-bottom>.rt-pagination {
  justify-content: space-between;
}
