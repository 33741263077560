.im-entity-identifier-tooltip .tooltip-inner {
    background-color: white;
    color: inherit;
}

.im-entity-identifier-tooltip svg {
    fill: currentColor;
    vertical-align: middle;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

pre {
    white-space: pre-wrap;
    word-break: keep-all;
}
